<div class="view-wrapper list-page">
  <dx-data-grid class="grid theme-dependent"
    [dataSource]="contacts"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
  >
    <dxo-toolbar>
      <dxi-item location="before">
        <div class="grid-header">Contacts</div>
      </dxi-item>
      <dxi-item location="before">
        <div *dxTemplate>
          <app-gerer-grid-state [dataGrid]="dataGrid" [gridStorageKey]="grilleStorageKey"></app-gerer-grid-state>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="setContacts()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>
    <!-- Options -->
    <dxo-state-storing [enabled]="true" [storageKey]="grilleStorageKey" appStorage></dxo-state-storing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-scrolling showScrollbar="always"></dxo-scrolling>
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-editing [allowAdding]="true" [allowUpdating]="false" mode="row"></dxo-editing>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

    <!-- Columns -->
    <dxi-column [allowHeaderFiltering]="true" dataField="id" caption="Id"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="clientIntitule" caption="Client"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="clientCodeBo" caption="Code Client"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="relationsString" caption="Rôles"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="intitule" caption="Intitulé"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="nom" caption="Nom"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="prenom" caption="Prénom"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="fonction" caption="Fonction"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" [visible]="false" dataField="telephoneBureau" caption="Téléphone bureau" width="125"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" [visible]="false" dataField="telephoneMobile" caption="Téléphone mobile" width="125"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" [visible]="false" dataField="telephoneDomicile" caption="Téléphone domicile" width="130"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="email" cellTemplate="mailTemplate" caption="Email" width="180">
      <div *dxTemplate="let d of 'mailTemplate'">
        <a href="mailto:{{d.data['intitule']}}<{{d.data['email']}}>">{{ d.data['email'] }}</a>
      </div>
    </dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="accesPortail" cellTemplate="cellTemplateBoolean" dataType="boolean" caption="Portail" width="80"></dxi-column>
    <dxi-column [allowHeaderFiltering]="true" dataField="actif" cellTemplate="cellTemplateBoolean" dataType="boolean" caption="Actif" width="80"></dxi-column>
    <div *dxTemplate="let data of 'cellTemplateBoolean'">
      <i class="dx-icon-check" *ngIf="data.value"></i>
    </div>
  </dx-data-grid>
</div>
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { PersonnalisationGrilleDto, PersonnalisationGrillesLexiClient } from "@lexi-clients/lexi";
import { StorageService } from '../../../services/storage.service';
import { filter, first, lastValueFrom, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../references/references';
import { DxListComponent } from 'devextreme-angular';

@Component({
  selector: 'app-gerer-grid-state',
  templateUrl: './gerer-grid-state.component.html',
  styleUrls: ['./gerer-grid-state.component.scss'],
})
export class GererGridStateComponent implements OnDestroy {
  //#region Variables
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid; }
  @Input() set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value) this.onInit();
  }

  private _gridStorageKey: string;
  get gridStorageKey() { return this._gridStorageKey; }
  @Input() set gridStorageKey(value: string) {
    this._gridStorageKey = value;
    if (value) this.onInit();
  }

  private subscriptions = new Subscription();
  selectedPersonnalisationGrille: PersonnalisationGrilleDto;
  dataSource: PersonnalisationGrilleDto[] = [];
  isDropDownBoxOpened: boolean = false;

  constructor(
    private readonly personnalisationGrillesLexiClient: PersonnalisationGrillesLexiClient,
    private readonly storageService: StorageService
  ) { }
  //#endregion

  //#region Méthodes principales
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async onInit() {
    if (this.gridStorageKey == null || this.dataGrid == null) return;

    this.storageService.currentGrilleSelected$.pipe(
      filter(x => x != null && x.storageKey == this.gridStorageKey),
      first()
    ).subscribe(async (grille) => {
      this.dataSource = this.storageService.getFromLocalStorage(this.gridStorageKey);
      if (this.dataSource == null || this.dataSource.length == 0) await this.setDataSource();
      this.selectedPersonnalisationGrille = grille;
    });

    this.subscriptions.add(
      this.storageService.currentGrilleSaved$.pipe(
        filter((grille: PersonnalisationGrilleDto) => grille != null)
      ).subscribe((grille: PersonnalisationGrilleDto) => {
        if (this.dataSource == null) return;
        if (grille.id == this.selectedPersonnalisationGrille?.id) {
          this.selectedPersonnalisationGrille = grille;
        }

        // On met à jour la dataSource à chaque fois qu'on met à jour la grille
        this.dataSource = this.dataSource.some(el => el.id === grille.id)
          ? this.dataSource.map(el => el.id === grille.id ? grille : el)
          : [...this.dataSource, grille];
      })
    );
  }

  async setDataSource() {
    if (this.gridStorageKey == null) return;
    this.dataSource = await lastValueFrom(this.personnalisationGrillesLexiClient.getByStorageKey(this.gridStorageKey));
    // On enregistre en localStorage les derniers changements
    this.storageService.saveToLocalStorage(this.gridStorageKey, this.dataSource);
  }
  //#endregion

  //#region Gestion des évènements
  onStateResetClick = async (e: { itemData: PersonnalisationGrilleDto }) => {
    if (this.dataGrid == null) return;
    const confirmed = await confirm("Réinitialiser l'affichage par défaut ?", "Confirmation");
    if (confirmed) {
      this.dataGrid.instance.state(null);
      const grille = await this.storageService.saveDxDataGridStateOnServerAndLocalStorage(e.itemData, this.dataGrid.instance.state());
      notify({ closeOnClick: true, message: `Grille réinitialiser sur l'affichage par défaut avec succès.` }, NotificationType.Success);
      this.selectGrille(grille);
    }
  }

  changeDropDownBoxValue(args: { addedItems: PersonnalisationGrilleDto[]; }) {
    const grille = args.addedItems[0];
    if (grille) this.revertToServerGrilleState(grille);
  }

  async addItem () {
    try {
      const data: PersonnalisationGrilleDto = {
        intitule: this.selectedPersonnalisationGrille.intitule,
        storageKey: this.gridStorageKey
      };

      if (data.intitule == null || data.intitule.trim() == '') {
        notify({ closeOnClick: true, message: `Veuillez renseigner un intitulé pour la préférence de la grille.` }, NotificationType.Warning);
        return;
      }

      const grille = await this.storageService.saveDxDataGridStateOnServerAndLocalStorage(data, this.dataGrid.instance.state(), true);
      await this.setDataSource();
      notify({ closeOnClick: true, message: `Enregistrement des préférences de la grille fait avec succès.` }, NotificationType.Success);
      this.selectGrille(grille);
    }
    catch {
      await this.setDataSource();
    }
  }

  save = async () => {
    try {
      await this.storageService.saveDxDataGridStateOnServerAndLocalStorage(this.selectedPersonnalisationGrille, this.dataGrid.instance.state(), true);
      notify({ closeOnClick: true, message: `Enregistrement des préférences de la grille fait avec succès.` }, NotificationType.Success);
    }
    finally {
      await this.setDataSource();
    }
  }

  async onItemDeleting(e: { cancel: boolean, itemData: PersonnalisationGrilleDto }) {
    try {
      const grille = e.itemData;
      await lastValueFrom(this.personnalisationGrillesLexiClient._delete(grille.id));
      notify({ closeOnClick: true, message: `Suppression des préférences de la grille faite avec succès.` }, NotificationType.Success);

      // On enregistre en localStorage les derniers changements
      this.dataSource = this.dataSource.filter(x => x.id != grille.id);
      this.storageService.saveToLocalStorage(this.gridStorageKey, this.dataSource);

      // Si on supprime la personnalisation en cours, on s'assure d'avoir une autre grille sélectionnée
      if (grille.id == this.selectedPersonnalisationGrille.id) {
        const grille = this.dataSource.length > 0 ? this.dataSource[0] : await this.storageService.getGrille(this.gridStorageKey);
        if (grille) this.selectGrille(grille);
      }
    }
    finally {
      await this.setDataSource();
    }
  }

  revertToServerGrilleState = async (data: PersonnalisationGrilleDto) => {
    try {
      const grille = await this.storageService.getFromServer(this.gridStorageKey, data.id);
      this.selectGrille(grille);
    }
    catch (ex: any) {
      notify({ closeOnClick: true, message: ex }, NotificationType.Error);
    }
  }
  //#endregion

  //#region Méthodes privées
  private selectGrille(grille: PersonnalisationGrilleDto) {
    this.isDropDownBoxOpened = false;
    this.selectedPersonnalisationGrille = grille;
    this.dataGrid.instance.state(JSON.parse(grille.data));
    this.storageService.selectGrille(grille);
  }
  //#endregion

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { AuthService } from '../settings/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService  {
  baseViewerUrl: string;
  baseEditorUrl: string;
  constructor( envService: EnvironmentService, private authService: AuthService) {
    this.baseViewerUrl = envService.environment.baseDxUrl + '/report/viewer';
    this.baseEditorUrl = envService.environment.baseDxUrl + '/report/designer';
  }

  open(reportId : string, params: string) {
    const societeId = this.authService.currentSocieteId;
    const siteId = this.authService.currentSiteId;

    params += '&currentSiteId=' + siteId + '&currentSocieteId=' + societeId;

    window.open(this.baseViewerUrl + '/' + reportId + '?' + params, '_blank');
  }

  openDesigner(reportId: string)
  {
    window.open(this.baseEditorUrl + '/' + reportId, '_blank');
  }
}

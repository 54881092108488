import { currentTheme, refreshTheme } from 'devextreme/viz/themes';
import themes from 'devextreme/ui/themes';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'lexi-angular/src/environments/environment';

const themesList = [
    { id: 'fluent.blue.light', text: 'Fluent Blue Light', type: 'fluent' },
    { id: 'fluent.blue.dark', text: 'Fluent Blue Dark', type: 'fluent' },
    { id: 'fluent.blue.light.compact', text: 'Fluent Blue Light Compact', type: 'fluent' },
    { id: 'fluent.blue.dark.compact', text: 'Fluent Blue Dark Compact', type: 'fluent' },
    { id: 'fluent.saas.light', text: 'Fluent SaaS Light', type: 'fluent' },
    { id: 'fluent.saas.dark', text: 'Fluent SaaS Dark', type: 'fluent' },
    { id: 'fluent.saas.light.compact', text: 'Fluent SaaS Light Compact', type: 'fluent' },
    { id: 'fluent.saas.dark.compact', text: 'Fluent SaaS Dark Compact', type: 'fluent' },
    { id: 'fluent.idt.light', text: 'IDT Light', type: 'fluent' },

    // TODO : Réintégrer quelques thèmes material quand possible
    { id: 'material.blue.light.compact', text: 'Material Blue Light Compact', type: 'material' },
    { id: 'material.blue.dark.compact', text: 'Material Blue Dark Compact', type: 'material' },
    // { id: 'material.lime.light.compact', text: 'Lime Light Compact', type: 'material' },
    // { id: 'material.lime.dark.compact', text: 'Lime Dark Compact', type: 'material' },
    { id: 'material.orange.light.compact', text: 'Material Orange Light Compact', type: 'material' },
    { id: 'material.orange.dark.compact', text: 'Material Orange Dark Compact', type: 'material' }
    // { id: 'material.purple.light.compact', text: 'Purple Light Compact', type: 'material' },
    // { id: 'material.purple.dark.compact', text: 'Purple Dark Compact', type: 'material' },
    // { id: 'material.teal.light.compact', text: 'Teal Light Compact', type: 'material' },
    // { id: 'material.teal.dark.compact', text: 'Teal Dark Compact', type: 'material' },

    // { id: 'generic.light', text: 'Light', type: 'generic' },
    // { id: 'generic.light.compact', text: 'Light Compact', type: 'generic' },
    // { id: 'generic.dark', text: 'Dark', type: 'generic' },
    // { id: 'generic.dark.compact', text: 'Dark Compact', type: 'generic' },
    // { id: 'generic.carmine', text: 'Carmine', type: 'generic' },
    // { id: 'generic.carmine.compact', text: 'Camine Compact', type: 'generic' },
    // { id: 'generic.softblue', text: 'Softblue', type: 'generic' },
    // { id: 'generic.softblue.compact', text: 'Soft Blue Compact', type: 'generic' },
    // { id: 'generic.darkmoon', text: 'Darkmoon', type: 'generic' },
    // { id: 'generic.darkmoon.compact', text: 'Dark Moon Compact', type: 'generic' },
    // { id: 'generic.darkviolet', text: 'Darkviolet', type: 'generic' },
    // { id: 'generic.darkviolet.compact', text: 'Dark Violet Compact', type: 'generic' },
    // { id: 'generic.greenmist', text: 'Greenmist', type: 'generic' },
    // { id: 'generic.greenmist.compact', text: 'Green mist Compact', type: 'generic' },
    // { id: 'generic.contrast', text: 'Contrast', type: 'generic' },
    // { id: 'generic.contrast.compact', text: 'Contrast Compact', type: 'generic' }
] as const;

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private storageKey = 'dx-theme';
    public currentThemeId: string;
    public isDark: BehaviorSubject<boolean>;
    public isCompact: BehaviorSubject<boolean>;

    constructor() {
        this.currentThemeId = window.localStorage[this.storageKey];

        // Si le thème en storage n'existe pas, on le set avec un des thème disponible
        const themeIds = themesList.map(x => x.id);
        if (this.currentThemeId == null || !themeIds.some(x => x == this.currentThemeId)) {
            const themeDisponibles = this.getThemes();
            this.currentThemeId = this.getThemeById(themeDisponibles?.[0]?.id)?.id;
            window.localStorage.setItem(this.storageKey, this.currentThemeId);
        }

        this.isDark = new BehaviorSubject<boolean>(this.currentThemeId && this.currentThemeId.includes('dark'));
        this.isCompact = new BehaviorSubject<boolean>(this.currentThemeId && this.currentThemeId.includes('compact'));
    }

    public getThemes() {
        return themesList;
    }

    getThemeById(themeId: string) {
        return themesList.find(x => x.id == themeId);
    }

    setAppThemeByThemeId(id: string) {
        const theme = this.getThemeById(id);
        this.setAppTheme(theme);
    }

    setAppTheme(theme = this.getThemeById(this.currentThemeId)) {
        if (!theme) return;

        this.currentThemeId = theme.id;
        window.localStorage.setItem(this.storageKey, this.currentThemeId);
        this.isDark.next(this.currentThemeId === undefined || this.currentThemeId.includes("dark"));
        this.isCompact.next(this.currentThemeId === undefined || this.currentThemeId.includes("compact"));

        const body = document.body;
        if (this.isCompact.value) {
            body.classList.add('dx-compact');
        } else {
            body.classList.remove('dx-compact');
        }    

        themes.current(theme.id);
    }

    isFluent(): boolean {
        return themes.current() !== null && themes.current() && themes.current().includes("fluent");
    }

    async switchTheme() {
        const newTheme: string = this.isDark.value
            ? this.currentThemeId.replace('dark', 'light')
            : this.currentThemeId.replace('light', 'dark');
        this.setAppThemeByThemeId(newTheme);
    }
}
<dx-data-grid
	[dataSource]="variantes"
	[allowColumnReordering]="true"
	[columnAutoWidth]="true"
	[allowColumnResizing]="true"
	[showBorders]="true"
	(onRowInserting)="onRowInserting($event.data)"
	(onRowUpdating)="onRowUpdating($event)"
	(onRowRemoving)="onRowRemoving($event.key)"
	(onSaving)="onSaving($event)"
	(onInitNewRow)="onInitNewRow($event.data)"
>
	<!-- Toolbar -->
	<dxo-toolbar>
		<dxi-item>
			<div *dxTemplate>
				<app-reset-grid-state [dataGrid]="dataGrid" [stylingMode]="'text'"></app-reset-grid-state>
			</div>
		</dxi-item>
		<dxi-item>
			<div *dxTemplate>
				<dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="setVariantes()"></dx-button>
			</div>
		</dxi-item>
		<dxi-item name="addRowButton"></dxi-item>
	</dxo-toolbar>

	<!-- Options -->
	<dxo-editing mode="popup" [allowAdding]=true [allowUpdating]=true [allowDeleting]=true>
		<dxo-popup
			title="Variante"
			[showTitle]="true"
			[width]="700"
			[height]="350"
			[showCloseButton]="true"
		></dxo-popup>
		<dxo-form #form>
			<dxi-item dataField="libelleLong" [colSpan]="2">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>

			<dxi-item dataField="variation1CaracteristiqueId"
				[label]="{text: 'Variation 1'}"
				editorType="dxSelectBox"
				[editorOptions]="{
					dataSource: caracteristiques,
					searchEnabled: true,
					displayExpr: 'intitule',
					valueExpr: 'id',
					showClearButton: true,
					searchExpr: ['intitule'],
					showDataBeforeSearch: true,
					readOnly: article?.variation2CaracteristiqueId != null,
				}"
			>
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
			<dxi-item *ngIf="caracteristiqueValeurs1.length > 0 " dataField="variation1Valeur"
				editorType="dxSelectBox"
				[editorOptions]="{
					dataSource: caracteristiqueValeurs1,
					searchEnabled: true,
					displayExpr: 'intitule',
					valueExpr: 'intitule',
					showClearButton: true,
					searchExpr: ['intitule'],
					showDataBeforeSearch: true,
				}"
			></dxi-item>
			<dxi-item *ngIf="caracteristiqueValeurs1.length == 0" dataField="variation1Valeur"></dxi-item>

			<dxi-item dataField="variation2CaracteristiqueId"
				[label]="{text: 'Variation 2'}"
				editorType="dxSelectBox"
				[editorOptions]="{
					dataSource: caracteristiques,
					searchEnabled: true,
					displayExpr: 'intitule',
					valueExpr: 'id',
					showClearButton: true,
					searchExpr: ['intitule'],
					showDataBeforeSearch: true,
					readOnly: article?.variation2CaracteristiqueId != null,
				}"
			></dxi-item>
			<dxi-item *ngIf="caracteristiqueValeurs2.length > 0" dataField="variation2Valeur"
				editorType="dxSelectBox"
				[editorOptions]="{
					dataSource: caracteristiqueValeurs2,
					searchEnabled: true,
					displayExpr: 'intitule',
					valueExpr: 'intitule',
					showClearButton: true,
					searchExpr: ['intitule'],
					showDataBeforeSearch: true,
				}"
			></dxi-item>
			<dxi-item *ngIf="caracteristiqueValeurs2.length == 0" dataField="variation2Valeur"></dxi-item>

		</dxo-form>
	</dxo-editing>
	<dxo-state-storing [enabled]="true" storageKey="grille_fiche_article_variantes" appStorage></dxo-state-storing>

	<!-- Colonnes -->
	<dxi-column dataField="codeBo" caption="Code" [allowEditing]="false" sortOrder="asc" cellTemplate="articleCellTemplate">
		<div *dxTemplate="let data of 'articleCellTemplate'">
			<a routerLink="/article/{{data.data.id}}">
				{{data.value}}
			</a>
		</div>
	</dxi-column>
	<dxi-column dataField="libelleLong" caption="Intitulé"></dxi-column>
	<dxi-column dataField="variation1CaracteristiqueIntitule" caption="Variation 1"></dxi-column>
	<dxi-column dataField="variation1Valeur" caption="Valeur 1"></dxi-column>
	<dxi-column dataField="variation2CaracteristiqueIntitule" caption="Variation 2"></dxi-column>
	<dxi-column dataField="variation2Valeur" caption="Valeur 2"></dxi-column>
	<dxi-column dataField="variation1CaracteristiqueId" [visible]="false"></dxi-column>
	<dxi-column dataField="variation2CaracteristiqueId" [visible]="false"></dxi-column>
</dx-data-grid>

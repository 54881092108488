<div *ngIf="!showPage" class="white-overlay">
  <dx-load-indicator class="center" [visible]="!showPage" height="50" width="50"></dx-load-indicator>
</div>

<dx-scroll-view>
  <div style="padding : 12px 24px; height : 100%;">
    <div id="wrapperSansLignesDuBon">
      <!-- #region EN-TETE Document logistique -->
      <a *ngIf="documentLogistique" style="display: block; text-decoration: none; cursor: pointer;" (click)="onNavigateToDocumentLogistiqueParent()">
        <app-document-logistique-entete
          [showBackButton]="false"
          [document]="documentLogistique"
          [isCreation]="false"
          [isModificationEnCours]="false"
          [fluxStatutDataSource]="fluxStatutDataSource"
          [mouvementTypeIntitule]="documentLogistique?.mouvementTypeIntitule"
        ></app-document-logistique-entete>
      </a>
      <!-- #endregion -->

      <!-- Entête -->
      <div [ngStyle]="{ 'margin-left': bon?.documentLogistiqueId ? '24px' : ''}">
        <app-bon-detail-entete
          [isCreation]="isCreation"
          [isModificationEnCours]="isModificationEnCours"
          [canEditDateSouhaite]="userIsInitiateur && isCreationOuBrouillon && isModificationEnCours"
          [bon]="bon"
          [mouvementTypeIntitule]="currentMouvementType?.intitule"
          [fluxStatutDataSource]="fluxStatutDataSource"
        ></app-bon-detail-entete>
      </div>

      <!-- #region Cartes Partenaires et lieux de stockage -->
      <div id="bonDetailLieuStockageWrapper" style="margin-top: 12px; display: block;">
        <app-bon-detail-lieu-stockage #bonDetailLieuStockageRef
                                      [isCreation]="isCreation"
                                      [disableAllBtn]="disableAllBtn"
                                      [isModificationEnCours]="isModificationEnCours"
                                      [userIsDestination]="userIsDestination"
                                      [userIsSource]="userIsSource"
                                      [showNoSerieInputs]="showNoSerieInputs"
                                      [bon]="bon"
                                      [bonSens]="bonSens"
                                      [currentSitePartenaireId]="currentSitePartenaireId"
                                      [currentMouvementType]="currentMouvementType"
                                      [lieuStockageDataSource]="lieuStockageDataSource"
                                      [partenaireSourceDataSource]="partenaireSourceDataSource"
                                      [partenaireDestinationDataSource]="partenaireDestinationDataSource"
                                      [canEditLieuStockage]="canEditLieuStockage"
                                      [canEditPartenaireSource]="canEditPartenaireSource"
                                      [canEditPartenaireDestination]="canEditPartenaireDestination"
                                      (stockageChanged)="onStockageChanged($event)"
        ></app-bon-detail-lieu-stockage>
      </div>
      <!-- #endregion -->

      <!-- Boutons -->
      <div class="padding-top-0" style="margin-top: 12px;">
        <!-- Bouton pour afficher / cacher les cartes de lieux de stockage -->
        <dx-button icon="menu" (onClick)="onToggleLieuStockageDisplay()" hint="Afficher / Cacher" style="float: left; margin-right: 12px;"></dx-button>

        <!-- Cas : Création d'un nouveau bon -->
        <ng-container *ngIf="isCreation">
          <dx-button text="Enregistrer" type="success" icon="save" (onClick)="onCreateNewBon()" [disabled]="disableAllBtn" style="margin-right: 12px;"></dx-button>
          <dx-button text="Annuler" type="danger" icon="undo" (onClick)="onNavigateToBonList()" [disabled]="disableAllBtn"></dx-button>
        </ng-container>

        <!-- Cas : Modification d'un bon -->
        <ng-container *ngIf="!isCreation">
          <div style="display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 12px;">
            <!-- Boutons pour modifier et enregistrer le bon si l'utilisateur a un droit de modification -->
            <div *ngIf="(userIsInitiateur && isCreationOuBrouillon) || canEditQuantiteReservee; else defaultBtnsTemplate">
              <span [title]="isComptageEnCours ? 'Un comptage est en cours.': ''">
                <dx-button *ngIf="!isModificationEnCours && canGererBons" text="Modifier" (onClick)="isModificationEnCours = true"
                          [disabled]="disableAllBtn || isComptageEnCours !== false" style="margin-right: 12px;"
                ></dx-button>
              </span>
              <dx-button *ngIf="!isModificationEnCours && canGererBons" text="Dupliquer" (onClick)="onDupliquerBon()" [disabled]="disableAllBtn" style="margin-right: 12px;"></dx-button>
              <dx-button *ngIf="!isModificationEnCours" (onClick)="onPrintBon()" [disabled]="isPrinting" style="margin-right: 12px;">
                <div *dxTemplate="let data of 'content'">
                  <dx-load-indicator [visible]="isPrinting" class='button-indicator' height="20" width="20"></dx-load-indicator>
                  <i *ngIf="!isPrinting" class="dx-icon dx-icon-print"></i>
                  <span class='dx-button-text' style="margin-left: .25rem;">Imprimer</span>
                </div>
              </dx-button>
              <dx-button
                style="vertical-align: bottom; margin-left: 10px; margin-right: 12px"
                text="Impression étiquettes"
                icon="download"
                displayExpr="libelle" keyExpr="id"
                [disabled]="!anyMarchandiseSelected"
                (onClick)="showImpressionPopup()">
              </dx-button>
              <dx-button *ngIf="isModificationEnCours" text="Annuler" type="danger" (onClick)="onCancel()" [disabled]="disableAllBtn" style="margin-right: 12px;"></dx-button>
              <dx-button *ngIf="isModificationEnCours" text="Enregistrer" type="success" (onClick)="onUpdateBon()" [disabled]="disableAllBtn" style="margin-right: 12px;"></dx-button>
              <dx-button (onClick)="showImportPopup = true;" [visible]="isImporterLignesCsvActive" style="margin-right: 12px;">
                <div *dxTemplate="let data of 'content'">
                  <dx-load-indicator [visible]="isPrinting" class='button-indicator' height="20" width="20"></dx-load-indicator>
                  <i class="dx-icon dx-icon-upload"></i>
                  <span class='dx-button-text' style="margin-left: .25rem;">Importer articles</span>
                </div>
              </dx-button>
            </div>

              <!-- Boutons disponible en tout temps -->
              <ng-template #defaultBtnsTemplate>
                <div>
                  <dx-button *ngIf="canGererBons" text="Dupliquer" (onClick)="onDupliquerBon()" style="margin-right: 12px;"></dx-button>
                  <dx-button text="Imprimer" icon="print" (onClick)="onPrintBon()"></dx-button>
                  <dx-button
                    style="vertical-align: bottom; margin-left: 10px;"
                    text="Impression étiquettes"
                    icon="download"
                    displayExpr="libelle" keyExpr="id"
                    [disabled]="!anyMarchandiseSelected"
                    (onClick)="showImpressionPopup()"
                  ></dx-button>
                </div>
              </ng-template>

            <dx-drop-down-button
                *ngIf="canAfficherQuantitesTheoriquesSurUnBonInventaire && canFaireImageDuStock && (bon?.fluxStatut == fluxStatut.new || bon?.fluxStatut == fluxStatut.opened) && bonSens == mouvementSens.inventaire"
                text="Faire l'image du stock"
                [items]="listePossibleActionImageStock"
                [disabled]="disableBoutonImageStock"
                keyExpr="id"
                class="dropdown-actions"
                (onItemClick)="onCopieImageStockClick($event)"
                style="min-width: 160px; margin-right: auto; margin-left: 1rem;"
                itemTemplate="actionImageStockTemplate"
              >
                <div *dxTemplate="let data of 'actionImageStockTemplate'">
                  <div [title]="data.description">{{ data.intitule }}</div>
                </div>
              </dx-drop-down-button>

            <!-- Liste des boutons des étapes possibles -->
            <app-flux-etape-possible-list
              [interfaceActive]="!disableAllBtn && !isModificationEnCours && isComptageEnCours === false"
              [currentSocieteId]="currentSocieteId"
              [currentUserId]="currentUserId"
              [currentObjectType]="ObjectType.bon"
              [currentObjectId]="currentBonId"
              [currentFluxId]="bon?.evenementFluxId"
              [currentEvenementTypeId]="bon?.evenementTypeId"
              [motifInterfaceInactive]="isComptageEnCours ? 'Un comptage est en cours': ''"
              (etapeClicked)="onClickEtape($event)"
            ></app-flux-etape-possible-list>
          </div>
        </ng-container>
      </div>

      <dx-button
        *ngIf="currentMouvementType && currentMouvementType.modeReservation != modeReservation.aucune && hasEtapeActionReserver && bon?.sens != mouvementSens.inventaire"
        text="Vérifier la disponibilité"
        (onClick)="verifierQuantite()"
      />
    </div>

    <!-- TabPanel - Lignes du bon -->
    <div id="wrapperLignesDuBon" style="margin-top: 12px;" *ngIf="showLignes && bon">
      <app-bon-detail-lignes #bonDetailLignesRef
                            [bon]="bon"
                            [bonStrategieComptage]="bonStrategieComptage"
                            [conditionnementTypeParDefaut]="conditionnementTypeParDefaut"
                            [bonSens]="bonSens"
                            [isCreation]="isCreation"
                            [isModificationEnCours]="isModificationEnCours"
                            [isComptageEnCours]="isComptageEnCours"
                            [canEditQuantiteInitiale]="canEditQuantiteInitiale"
                            [canEditQuantiteReservee]="canEditQuantiteReservee"
                            [canAddMarchandises]="userIsInitiateur && !isBonEntreeFromFlottant && isCreationOuBrouillon"
                            [canGererEcrituresComptables]="canGererEcrituresComptables"
                            [canGererParametres]="canGererParametres"
                            [canAfficherQuantitesTheoriquesSurUnBonInventaire]="canAfficherQuantitesTheoriquesSurUnBonInventaire"
                            [canAfficherMouvementsStock]="canAfficherMouvementsStock"
                            [canAfficherPmp]="canAfficherPmp"
                            [showNoSerieInputs]="showNoSerieInputs"
                            [currentBonId]="currentBonId"
                            [currentSiteId]="currentSiteId"
                            [currentSiteId]="currentSitePartenaireId"
                            [uniteDataSource]="uniteDataSource"
                            [marchandiseLignes]="marchandiseLignes"
                            [isCreationOuBrouillon]="isCreationOuBrouillon"
                            [userIsSource]="userIsSource"
                            [userIsDestination]="userIsDestination"
                            [hasDocumentLogistique]="hasDocumentLogistique"
                            [ecritureLignes]="ecritureLignes"
                            [toggle]="toggle"
                            [indexOngletBon]="indexOngletBon"
                            [showMultiQuantiteesComptees]="showMultiQuantiteesComptees"
                            (refreshStock)="refreshStock($event)"
                            (comptageChanged)="onComptageChanged()"
                            (marchandiseSelected)="handleMarchandiseSelected($event)"
                            (refreshLignes)="onRefreshLignes()"
      ></app-bon-detail-lignes>
    </div>
  </div>
</dx-scroll-view>

<!-- Popup : Confirmation des quantités mouvementés -->
<dx-popup
  title="Confirmer les quantités à mouvementer"
  [(visible)]="showConfirmationQuantitePopup"
  [showCloseButton]="false"
  [resizeEnabled]="true"
  [height]="600"
  [width]="850"
  (onHiding)="onConfirmationQuantitePopupHiding($event)"
>
  <dxi-toolbar-item location="after" toolbar="top">
    <div *dxTemplate style="display: flex; align-items: center;">
      <ng-container *ngIf="anyMarchandiseLignesForPopupHasEcart">
        <span class="dx-icon-warning" style="font-size: 20px; color: rgb(204, 187, 38); margin-right: 6px;"></span>
        <b>Écart présent</b>
      </ng-container>
    </div>
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <dx-data-grid
      [dataSource]="marchandiseLignesForPopup"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      [showBorders]="true"
      [height]="470"
    >
      <!-- Options -->
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
      <dxo-scrolling showScrollbar="always"></dxo-scrolling>

      <!-- Colonnes -->
      <dxi-column dataField="articleCodeBo" caption="Code" [width]="102"></dxi-column>
      <dxi-column dataField="articleIntitule" caption="Article"></dxi-column>
      <dxi-column dataField="stockSource.quantiteDisponible" caption="Stock Source" dataType="number" [width]="123" [visible]="userIsSource && bonSens != mouvementSens.inventaire"></dxi-column>
      <dxi-column dataField="stockDestination.quantiteDisponible" caption="Stock Dest." dataType="number" [width]="110" [visible]="userIsDestination && bonSens != mouvementSens.inventaire"></dxi-column>
      <dxi-column dataField="quantiteInitiale" [caption]="nomQuantiteInitiale" dataType="number" [width]="bonSens == mouvementSens.inventaire ? 107 : 90"></dxi-column>
      <dxi-column dataField="quantiteReservee" [caption]="nomQuantiteReservee" dataType="number" [width]="110"></dxi-column>
      <dxi-column dataField="ecart"
                  caption="Écart"
                  dataType="number"
                  [width]="75"
                  [visible]="anyMarchandiseLignesForPopupHasEcart"
                  [sortOrder]="anyMarchandiseLignesForPopupHasEcartNegatif ? 'desc' : 'asc'"
                  cellTemplate="ecartCellTemplate"
      >
        <div *dxTemplate="let data of 'ecartCellTemplate'" style="display: flex; align-items: center; justify-content: flex-end;" [title]="getTooltipMessagePourLigneSerialiseEnEcart(data)">
          <ng-container *ngIf="data.value !== 0">
            <span class="dx-icon-warning" [style]="getStylePourEcart(data)"></span>
            <b><span *ngIf="data.value > 0">+</span>{{data.value}}</b>
          </ng-container>
        </div>
      </dxi-column>
      <dxi-column dataField="uniteId" caption="Unité" [width]="90">
        <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
      </dxi-column>
    </dx-data-grid>
  </div>

  <dxi-toolbar-item location="after" toolbar="bottom" *ngIf="showAlerteStockInsuffisant">
    <div *dxTemplate style="display: flex; align-items: center;">
      <span class="dx-icon-warning" style="font-size: 20px; color: red; margin-right: 6px;"></span>
      <span>Impossible de finaliser : stock insuffisant</span>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item location="after"
                    toolbar="bottom"
                    widget="dxButton"
                    [options]="{
      text: 'Annuler',
      stylingMode: 'contained',
      type: 'danger',
      onClick: closeConfirmationPopup
    }"
  ></dxi-toolbar-item>
  <dxi-toolbar-item location="after"
    toolbar="bottom"
  >
    <div *dxTemplate [title]="getTooltipMessageConfirmerBon">
      <dx-button [text]="anyMarchandiseLignesForPopupHasEcartNegatif && bonSens != mouvementSens.inventaire ? 'Confirmer sans reliquat' : 'Confirmer'" (onClick)="onConfirmationQuantiteMouvementee($event)" type="success"
                 [disabled]="anyMarchandiseSerialiseesLignesHasEcart"
      ></dx-button>
    </div>

  </dxi-toolbar-item>
  <dxi-toolbar-item location="after"
                    toolbar="bottom"
                    widget="dxButton"
                    [options]="{
      visible: anyMarchandiseLignesForPopupHasEcartNegatif && bonSens != mouvementSens.inventaire,
      text: 'Confirmer avec un reliquat',
      stylingMode: 'contained',
      type: 'success',
      onClick: onConfirmationQuantiteMouvementee,
      elementAttr: { 'data-reliquat': 'Avec reliquat' }
    }"
  ></dxi-toolbar-item>
</dx-popup>

<!-- Popup : Erreur lieu de stockage manquant -->
<dx-popup
  title="Erreur : Lieu de stockage manquant"
  [(visible)]="showErrorPopup"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="400"
  [height]="125"
>
  <div *dxTemplate="let data of 'content'">
    {{ errorPopupMessage }}
  </div>
  <dxi-toolbar-item location="after"
                    widget="dxButton"
                    toolbar="bottom"
                    [options]="{
      text: 'OK',
      onClick: closeErrorPopup
    }"
  ></dxi-toolbar-item>
</dx-popup>

<!-- Popup : Créer un bon avec reliquat -->
<dx-popup
  title="Création d'un reliquat"
  [(visible)]="showCreationReliquatPopup"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [height]="170"
  [width]="800"
>
  <div *dxTemplate="let data of 'content'">
    <dx-form [formData]="bonFromReliquatDto">
      <dxi-item dataField="intitule" [label]="{text: 'Intitule'}"></dxi-item>
    </dx-form>
  </div>

  <dxi-toolbar-item location="after"
                    toolbar="bottom"
                    widget="dxButton"
                    [options]="{
      text: 'Créer',
      stylingMode: 'contained',
      type: 'success',
      onClick: onConfirmationCreationReliquat,
    }"
  ></dxi-toolbar-item>
</dx-popup>

<!-- Popup : Vérifier disponibilité (écart quantités disponible/initiale) -->
<dx-popup
  title="Vérification de la disponibilité"
  [(visible)]="showDisponibilitePopup"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [height]="600"
  [width]="800"
  (onHidden)="onPopupDisponibiliteHidden"
>
  <dx-data-grid
    id="disponibiliteMarchandiseBonDataGrid"
    #disponibiliteMarchandiseBonDataGrid
    [dataSource]="disponibiliteDataSource"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    style="margin-top: 6px;"
  >
    <dxi-column dataField="articleCodeBo" caption="Code article" [width]="120"></dxi-column>
    <dxi-column dataField="articleIntitule" caption="Article"></dxi-column>
    <dxi-column dataField="quantiteInitiale" [caption]="nomQuantiteInitiale" [width]="120"></dxi-column>
    <dxi-column dataField="quantiteDisponible" caption="Disponible" [width]="120"></dxi-column>
    <dxi-column dataField="ecart" caption="Écart" [width]="100"></dxi-column>
  </dx-data-grid>

  <dxi-toolbar-item location="after"
                    toolbar="bottom"
                    widget="dxButton"
                    [options]="{
      text: 'Fermer',
      stylingMode: 'contained',
      type: 'success',
      onClick: onPopupDisponibiliteHidden,
    }"
  ></dxi-toolbar-item>
</dx-popup>

<!--Impression étiquettes popup-->
<dx-popup
  [title]= "getTitlePopupEtiquettes()"
  [(visible)]="showPopupImpressionEtiquette"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="800"
  [height]="'auto'">


  <div style="display: flex; flex-direction: column; gap: 12px">
    <div style="display: flex; align-items: center; gap: 12px">
      <h4>Modèle de l'étiquette</h4>
      <app-objecttype-edition-possible-list [objectType]="ObjectType.article" [isDropDownButton]="false" (templateIdSelected)="handleTemplateIdChange($event)"></app-objecttype-edition-possible-list>
    </div>

    <h4>Quantité à imprimer pour chaque article</h4>
    <div style="display: flex; align-items: center; gap: 12px">
      <dx-radio-group
        [dataSource]="optionNombreEtiquette"
        [items]="optionNombreEtiquette"
        displayExpr="libelle"
        valueExpr="option"
        [(ngModel)]="impressionEtiquetteArticleDto.optionNombreEtiquette"
        layout="horizontal"
      ></dx-radio-group>
      <dx-text-box type="number" placeholder="Nombre personnalisé" [(ngModel)]="nombreEtiquetteCustom" [disabled]="!isOptionNombreEtiquetteCustom()"/>
    </div>


    <dx-button icon="print" type="submit" text="Imprimer" (onClick)="handleImpressionEtiquette()" [disabled]="!informationsImpressionValide()"></dx-button>
  </div>
</dx-popup>


<div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center;">
  <dx-load-indicator id="large-indicator" class="center" [visible]="showLoader" height="50" width="50"></dx-load-indicator>
</div>

<dx-popup
  title="Importer des articles via fichier .csv"
  [(visible)]="showImportPopup"
  (onHiding)="onCloseImportPopup()"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="450"
  [height]="300"
>
  <dx-file-uploader #fileUploader
                    [multiple]="false"
                    [(value)]="files"
                    name="files[]"
                    [allowedFileExtensions]="['.csv']"
                    uploadMode="useForm"
                    selectButtonText="Choisir un fichier Excel"
                    labelText="ou Glisser/Déposer le fichier"
                    readyToUploadMessage=""
                    class="uploader">
  </dx-file-uploader>


  <div style="display: flex; justify-content: center; margin-top: 12px; gap:12px">
    <dx-button text="Modèle CSV" (onClick)="downloadTemplate()"></dx-button>
    <dx-button
      text="Annuler"
      (onClick)="onCloseImportPopup()"
    ></dx-button>

    <dx-button
      text="Importer"
      (onClick)="importerLignes()"
      [disabled]="files.length===0"
    ></dx-button>
  </div>

  <div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
    <dx-load-indicator [visible]="showLoader" height="50" width="50"></dx-load-indicator>
  </div>
</dx-popup>

<dx-form class="plain-styled-form"  [formData]="partenaireData" [screenByWidth]="getSizeQualifier">
  <dxi-item itemType="group" [colCount]="6">
    <dxo-col-count-by-screen [xs]="4"></dxo-col-count-by-screen>
    <dxi-item dataField="profilPartenaireId" [label]="{text: 'Profil'}" [colSpan]="4" editorType="dxSelectBox"
      [editorOptions]="{
            dataSource: profilPartenaires,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: false
          }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="codeBo" [colSpan]="2" editorType="dxTextBox" [label]="{ text: 'Code' }"
      [editorOptions]="{ maxLength: 36 }">
      <dxi-validation-rule type="required" message="Code obligatoire"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="intitule" [colSpan]="6" editorType="dxTextBox" [label]="{ text: 'Intitulé' }"
      [editorOptions]="{ maxLength: 120 }">
      <dxi-validation-rule type="required" message="L'intitulé est requis"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="email" [colSpan]="4" [label]="{ text: 'Courriel' }" [editorOptions]="{ maxLength: 150 }">
      <dxi-validation-rule type="stringLength" [max]="150"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="telecopie" [colSpan]="2" [label]="{ text: 'Fax' }"
    [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30}">
    <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
    <dxi-validation-rule type="pattern" [pattern]="phonePattern"
      [message]="phoneBrokePatternMessage"></dxi-validation-rule>
  </dxi-item>
    <dxi-item dataField="telephoneFixe" [colSpan]="2" [label]="{ text: 'Téléphone fixe' }"
      [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30 }">
      <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
      <dxi-validation-rule type="pattern" [pattern]="phonePattern"
        [message]="phoneBrokePatternMessage"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="telephonePortable" [colSpan]="2" [label]="{ text: 'Téléphone portable' }"
      [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30}">
      <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
      <dxi-validation-rule type="pattern" [pattern]="phonePattern"
        [message]="phoneBrokePatternMessage"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="telephoneAutre" [colSpan]="2" [label]="{ text: 'Téléphone autre' }"
      [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30}">
      <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
      <dxi-validation-rule type="pattern" [pattern]="phonePattern"
        [message]="phoneBrokePatternMessage"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="siteWeb" [colSpan]="6" [label]="{ text: 'Site internet' }"></dxi-item>
    <dxi-item dataField="estClient"       [colSpan]="3" editorType="dxCheckBox" [label]="{ text: 'Client' }" cssClass="partenaire_checkbox"></dxi-item>
    <dxi-item dataField="estFournisseur"  [colSpan]="3" editorType="dxCheckBox" [label]="{ text: 'Fournisseur' }" cssClass="partenaire_checkbox"></dxi-item>

  </dxi-item>
</dx-form>
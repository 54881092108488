<div style="display: flex; height : 48px;">
  <span [title]="isComptageEnCours ? 'Un comptage est en cours.': ''">
    <dx-button [visible]="!isPointe" text="Pointer" (onClick)="pointer()" style="margin-right: 12px;" [disabled]="isComptageEnCours"></dx-button>
  </span>
  <dx-button [visible]="isPointe" text="Annuler" (onClick)="annuler()" style="margin-right: 12px;" type="danger"></dx-button>
  <dx-button [visible]="isPointe" text="Valider" (onClick)="valider()" style="margin-right: 12px;" type="success"></dx-button>
  <dx-text-box [visible]="isPointe" [(ngModel)]="noSerieSaisi" (onEnterKey)="scan()" width="85%" placeholder="Saisir un numéro de série et appuyer sur entrée"></dx-text-box>
</div>

<dx-data-grid
  [dataSource]="lignes"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [showBorders]="true"
  height="calc(100% - 48px)"
>

  <!-- Options -->
  <dxo-editing [allowUpdating]="isPointe" mode="cell"></dxo-editing>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

  <!-- Colonnes -->
  <dxi-column dataField="pointe" caption="Pointé" [width]="100" [allowEditing]="true"></dxi-column>
  <dxi-column caption="Intitulé" [allowEditing]="false" [calculateCellValue]="calculateArticleCellValue" [groupIndex]="0"></dxi-column>
  <dxi-column dataField="referenceFournisseur" caption="Référence fournisseur" [width]="200" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="numeroSerie" caption="Numéro de série" [allowEditing]="false"></dxi-column>
</dx-data-grid>
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ArticleForUpdateDto, ArticleFournisseurDto, ArticleInfosHeriteesDeLaClassification, ArticlesLexiClient, ArticleType, ClassificationsLexiClient, CompteGeneralDto, ComptesGenerauxLexiClient, DocumentLexiClient, DocumentResultDto, GetClassificationDto, ImagesLexiClient, ObjectType, Permissions, SocieteToUpdateDto, StockageConnaissement, TarifRevatuaDto, TarifsRevatuaLexiClient, UniteDto, HistoriquePmpDto, CreateOrUpdateArticleRequestDto, LieuStockageArticleDto, LieuStockagesLexiClient, LieuStockageArticlesLexiClient, LieuStockageDto, CaracteristiqueDto, CaracteristiquesLexiClient } from "@lexi-clients/lexi";
import { HttpClient, HttpParams } from "@angular/common/http";
import notify from "devextreme/ui/notify";
import { NotificationType } from "../../modules/shared/references/references";
import DataSource from "devextreme/data/data_source";
import { PartenaireListService } from "../../services/partenaire.service";
import { DxFormComponent, DxTabPanelComponent } from "devextreme-angular";
import { TaxeGroupeListService } from "../../services/taxe-groupe.service";
import { ConditionnementListService } from "../../services/conditionnement-list.service";
import { UniteListService } from "../../services/unite.service";
import { FamilleListService } from "../../services/famille.service";
import { PaysListService } from "../../services/pays.service";
import { ArticleListService } from "../../services/article.service";
import { NomenclatureDouaneListService } from "../../services/nomenclature-douane.service";
import { SuffixeValeurListService } from "../../services/suffixe-valeur.service";
import { CodeExonerationListService } from "../../services/code-exoneration.service";
import { filter, lastValueFrom, Subscription } from "rxjs";
import { AuthService } from "../../settings/auth.service";
import { SocieteService } from "../../services/societe.service";
import { ValidationResult } from "devextreme/ui/validation_group";
import { ConfigFiltreMouvement } from "../../modules/shared/mouvement-stock-datagrid/mouvement-stock-datagrid.component";
import { DxDataSourceService } from "../../shared/services/dx-data-source.service";
import { ArticleDetailBonListComponent } from "../../modules/shared/article-detail-bon-list/article-detail-bon-list.component";
import { ConfigFiltreLot, LotStockageDatagridComponent } from "../../modules/shared/lot-stockage-datagrid/lot-stockage-datagrid.component";
import { DocumentListComponent } from "../document-list/document-list.component";
import { confirm } from 'devextreme/ui/dialog';
import { LieuStockageArticleComponent } from "../../modules/shared/lieux-stockage/lieu-stockage-article/lieu-stockage-article.component";
import { DxoTabPanelOptionsComponent } from "devextreme-angular/ui/nested";
import { VariantesArticleListComponent } from "../variantes-article-list/variantes-article-list.component";

@Component({
  selector: "app-article-detail",
  templateUrl: "./article-detail.component.html",
  styleUrls: ["./article-detail.component.scss"],
})
export class ArticleDetailComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly storedArticleToDuplicateKey = "ARTICLE_A_DUPLIQUER";
  readonly doRefreshKey = "FORCER_RELOAD_ARTICLE_DETAIL";
  @ViewChild("form") form: DxFormComponent;
  @ViewChild("tabPanelGeneralOptions", { static: false }) tabPanelGeneralOptions: DxoTabPanelOptionsComponent;
  @ViewChild(ArticleDetailBonListComponent) articleDetailBonListRef: ArticleDetailBonListComponent;
  @ViewChild(DocumentListComponent) documentListComponentRef: DocumentListComponent;
  @ViewChild(LotStockageDatagridComponent) lotStockageDatagridRef: LotStockageDatagridComponent;
  @ViewChild(LieuStockageArticleComponent) lieuStockageArticleRef: LieuStockageArticleComponent;
  @ViewChild(VariantesArticleListComponent) variantesArticleRef : VariantesArticleListComponent;

  objectType = ObjectType;
  article: ArticleForUpdateDto;
  societe: SocieteToUpdateDto;
  /** Contient les infos héritées d'une classification pour l'article en cours (dont comptes comptables, infos frêt, etc...) */
  infosHeriteesDeLaClassification: ArticleInfosHeriteesDeLaClassification;
  isModificationEnCours: boolean = false;
  isCreation: boolean = false;
  showConfirmationTaxePopup: boolean = false;
  currentSiteId: number;
  currentPartenaireId: number;
  isInterfaceComptableActive: boolean = false;
  chargementInitialFait: boolean = false;
  isImageLoading: boolean = false;
  bonsHasLoaded = false;
  comptesGeneraux: CompteGeneralDto[];
  codeTarifRevatuaPlaceholder: string;
  stockageRevatuaPlaceholder: string;
  historiquePmp: HistoriquePmpDto[] = [];
  showPopupHistorique: boolean = false;
  articleAvecVariation: boolean = false;
  classifications: GetClassificationDto[] = [];
  configFiltre = <ConfigFiltreMouvement>{
    isByArticle: true,
    isByPartenaire: true,
    isBySociete: false,
    isByDocumentLogistique: false,
    isByBon: false,
  };
  configFiltreLot = <ConfigFiltreLot>{
    isByPartenaire: JSON.parse(
      sessionStorage.getItem("grille_lot_stockage_fiche_article_by_partenaire"),
    ),
  };
  lieuStockageArticleDataSource: LieuStockageArticleDto[];
  lieuStockageDataSource: LieuStockageDto[];

  tabTitlesEnum = TabTitlesEnum;
  dictionnaireTabTitles: { [key in TabTitlesEnum]: string } = {
    [TabTitlesEnum.Achats]: "Achats",
    [TabTitlesEnum.Audit]: "Audit",
    [TabTitlesEnum.Caracteristiques]: "Caractéristiques",
    [TabTitlesEnum.Classifications]: "Classifications",
    [TabTitlesEnum.ComptaDouane]: "Compta / Douane",
    [TabTitlesEnum.General]: "Général",
    [TabTitlesEnum.Historique]: "Historiques",
    [TabTitlesEnum.Logistique]: "Logistique",
    [TabTitlesEnum.NumeroSerie]: "Numéro de série",
    [TabTitlesEnum.Stock]: "Stock",
    [TabTitlesEnum.Variantes]: "Variantes",
    [TabTitlesEnum.Vente]: "Vente",
    [TabTitlesEnum.Documents]: "Documents",
    [TabTitlesEnum.Photos]: "Photos"
  }

  readonly actions = [
    { id: "edit", intitule: "Modifier" },
    { id: "dupliquer", intitule: "Dupliquer" },
  ];

  readonly articleTypeDataSource: Array<{ id: ArticleType, libelle: string }> =
  [
    { id: ArticleType.backOffice, libelle: "Stockable" },
    { id: ArticleType.commentaire, libelle: "Commentaire" },
    { id: ArticleType.metadata, libelle: "Metadata" },
    { id: ArticleType.kit, libelle: "Kit" },
    { id: ArticleType.prestation, libelle: "Service" },
    { id: ArticleType.interne, libelle: "Interne" },
    { id: ArticleType.libre, libelle: "Libre" },
    { id: ArticleType.generique, libelle: "Générique" },
    { id: ArticleType.fret, libelle: "Frêt" },
    { id: ArticleType.assurance, libelle: "Assurance" },
  ];
  // Acces à l'enum côté html
  ArticleType = ArticleType;

  /** DataSource de la dataGrid de l'onglet 'Achats' */
  referencesFournisseurs: ArticleFournisseurDto[];

  // DataSources liées aux SelectBox
  uniteDataSource: UniteDto[] = [];
  conditionnementDataSource: DataSource;
  taxeGroupeDataSource: DataSource;
  preparationZonesDataSource: DataSource;
  tarifRevatuaDataSource: TarifRevatuaDto[];
  articleAssuranceDataSource: DataSource;

  // DataSources liées aux SelectBox de l'onglet 'Classifications'
  familleDataSource: DataSource;
  articleDataSource: DataSource;

  // DataSources liées aux SelectBox de l'onglet 'Achats'
  partenaireDataSource: DataSource;

  // DataSources liées aux SelectBox de l'onglet 'Douane'
  nomenclatureDouaneDataSource: DataSource;
  suffixeValeurDataSource: DataSource;
  codeExonerationDataSource: DataSource;
  paysDataSource: DataSource;

  isModificationAutorisee: boolean = false;
  canGererBonAchat: boolean = false;
  canModifierPrixRevient: boolean = false;
  showEditPrixRevientPopup: boolean = false;
  prixRevientAvantModification: number;
  stockageRevatuaList = [
    { id: StockageConnaissement.cale, intitule: "Cale" },
    { id: StockageConnaissement.refrigere, intitule: "Réfrigérée" },
    { id: StockageConnaissement.congele, intitule: "Congelé" },
    { id: StockageConnaissement.pontee, intitule: "Pontée" },
  ];

  //Data sources liées aux photos de l'article
  listPhotos: DocumentResultDto[] = [];
  photoPrincipaleId: string | null;
  addedPhotos: File[] = [];
  selectedPhotoId: string;
  imageSrc: string | null;
  urlPhoto = window.location.origin + "/api/images/";

  uniteBaseSelectedId: number;
  caracteristiqueDataSource: CaracteristiqueDto[] = [];
  isClassificationsDuParent = false;
  showPopover = false;

  //Variables de controle des Tabs du DevExtremeTabPanel
  isTabLoaded: { [key: string]: boolean } = {};

  constructor(
    private http: HttpClient,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly articlesLexiClient: ArticlesLexiClient,
    private readonly taxeGroupeService: TaxeGroupeListService,
    private readonly conditionnementService: ConditionnementListService,
    private readonly familleService: FamilleListService,
    private readonly uniteService: UniteListService,
    private readonly partenaireService: PartenaireListService,
    private readonly paysService: PaysListService,
    private readonly articleService: ArticleListService,
    private readonly tarifsRevatuaLexiClient: TarifsRevatuaLexiClient,
    private readonly nomenclatureDouaneService: NomenclatureDouaneListService,
    private readonly suffixeValeurService: SuffixeValeurListService,
    private readonly codeExonerationService: CodeExonerationListService,
    private readonly authService: AuthService,
    private readonly societeService: SocieteService,
    private readonly compteGenerauxLexiClient: ComptesGenerauxLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly classificationsLexiClient: ClassificationsLexiClient,
    private documentService: DocumentLexiClient,
    private readonly imageLexiClient: ImagesLexiClient,
    private readonly cd: ChangeDetectorRef,
    private readonly lieuStockageLexiClient: LieuStockagesLexiClient,
    private readonly lieuStockageArticleLexiClient: LieuStockageArticlesLexiClient,
    private readonly caracteristiquesLexiClient: CaracteristiquesLexiClient
  ) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {
      try {
        this.chargementInitialFait = false;
        this.isImageLoading = true;
        const articleId = params["id"];

        this.isModificationAutorisee = this.authService.securityUserisGrantedWith(
          Permissions.canModifierFicheArticle,
        );
        this.canGererBonAchat = this.authService.securityUserisGrantedWith(
          Permissions.administrationBonsAchat,
        );
        this.canModifierPrixRevient = this.authService.securityUserisGrantedWith(
          Permissions.canModifierPrixRevientArticle,
        );

        // Création d'un nouvel article
        if (isNaN(Number(articleId))) {
          // Mauvais paramètre, on redirige sur la page de création d'un nouvel article
          if (articleId != "nouveau") {
            await this.router.navigate(["/article/nouveau"]);
            return;
          }
          this.isCreation = true;
          this.setNewArticle();
        }
        // Modificatifon d'un article existant
        else {
          await this.setArticle(articleId);
          this.isCreation = false;
        }

        this.lotStockageDatagridRef?.setDataSource();
        await this.loadEditors();

        if (!this.isCreation) {
          await this.setClassifications();
          // éxecution à la fin pour éviter le blocage de la fiche article
          await this.getListPhotos();
          await this.loadPhotoPrincipale();
        }
      }
      finally {
        this.isImageLoading = false;
        this.chargementInitialFait = true;
      }
    });

    this.subscriptions.add(
      this.authService.currentSiteId$
        .pipe(filter((siteId: number) => siteId != null))
        .subscribe((siteId: number) => {
          this.currentSiteId = siteId;
          this.currentPartenaireId = this.authService.currentSite.partenaireId;
        }),
    );

    this.subscriptions.add(
      this.societeService.currentSociete$
        .pipe(filter((societe: SocieteToUpdateDto) => societe != null))
        .subscribe(async (societe: SocieteToUpdateDto) => {
          this.societe = societe;
          this.isInterfaceComptableActive =
            societe.parametrageComptable?.interfaceActive ?? false;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async setClassifications() {
    let allClassifications = await lastValueFrom(
      this.classificationsLexiClient.getByItemId(this.objectType.article, this.article.id)
    );

    // Si c'est une variante sans classification, récupérer celles du parent
    if (allClassifications.length == 0 && this.article.articleParentId != null) {
      allClassifications = await lastValueFrom(
        this.classificationsLexiClient.getByItemId(this.objectType.article, this.article.articleParentId)
      );
      this.isClassificationsDuParent = allClassifications.length > 0;
    }
    else {
      this.isClassificationsDuParent = false;
    }

    this.classifications = allClassifications.filter(c =>
      c.classificationTypeId == this.societe.classificationSavId
      || c.classificationTypeId == this.societe.classificationComptableTypeId
    );
  }

  async loadEditors() {
    this.taxeGroupeDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.taxeGroupeService);
    this.uniteDataSource = (await lastValueFrom(this.uniteService.get(new HttpParams())))?.data ?? [];
    this.tarifRevatuaDataSource = await lastValueFrom(this.tarifsRevatuaLexiClient.getAll());
    this.comptesGeneraux = await lastValueFrom(this.compteGenerauxLexiClient.getAll());
    const articleAssuranceParams = new Map().set("articleType", ArticleType.assurance).set("avecVariante", true);
    this.articleAssuranceDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.articleService, articleAssuranceParams);
    this.caracteristiqueDataSource = await lastValueFrom(this.caracteristiquesLexiClient.getCaracteristiquesByObjectType(ObjectType.article));

    if (!this.isCreation) {
      const conditionnementAdditionalParams = new Map().set(
        "articleId",
        this.article?.id,
      );
      this.conditionnementDataSource =
        this.dxDataSourceService.getDataSourceForSelectBox(
          this.conditionnementService,
          conditionnementAdditionalParams,
        );
      await this.setReferencesFournisseurs();
    } else {
      this.uniteBaseSelectedId = this.uniteDataSource[0]?.id;
    }
  }

  async setReferencesFournisseurs() {
    this.referencesFournisseurs = await lastValueFrom(
      this.articlesLexiClient.getReferencesFournisseurByArticleId(
        this.article?.id,
      ),
    );
  }

  private setNewArticle() {
    if (localStorage.getItem(this.doRefreshKey)) {
      localStorage.removeItem(this.doRefreshKey);
      window.location.reload(); // Nécessaire pour rafraîchir la vue correctement (notamment le bouton "Créer")
      return;
    }

    const storedArticle = localStorage.getItem(
      this.storedArticleToDuplicateKey,
    );
    if (storedArticle) {
      this.article = JSON.parse(storedArticle);
      delete this.article.id;
      this.article.codeBo = null;
      localStorage.removeItem(this.storedArticleToDuplicateKey);
    } else {
      this.article = {
        suiviSav: false,
        typeArticle: "",
        type: ArticleType.backOffice,
        bloque: false,
        quantiteMinimumVente: 1,
        rapportTarifaire: 1,
        rapportPhysique: 1,
        nombreNumeroSerie: 0,
        _synchronized: false,
        isPgc: false,
        isPpn: false,
        uniteBaseId: this.uniteBaseSelectedId,
      };
    }
  }

  private async setArticle(articleId: number) {
    this.article = await lastValueFrom(this.articlesLexiClient.getForUpdate(articleId));
    this.articleAvecVariation = this.article.avecVariation;
    this.photoPrincipaleId = this.article.photoPrincipaleId;
    this.uniteBaseSelectedId = this.article.uniteBaseId;
    this.infosHeriteesDeLaClassification = await lastValueFrom(this.articlesLexiClient.getInfosHeriteesDeLaClassification(articleId));

    // Set placeholder CodeTarifRevatua
    if (
      this.article != null &&
      this.infosHeriteesDeLaClassification?.codeTarifRevatua != null
    ) {
      if (this.tarifRevatuaDataSource == null)
        this.tarifRevatuaDataSource = await lastValueFrom(
          this.tarifsRevatuaLexiClient.getAll(),
        );
      const tarifRevatua = this.tarifRevatuaDataSource.find(
        (x) => x.id == this.infosHeriteesDeLaClassification?.codeTarifRevatua,
      );
      this.codeTarifRevatuaPlaceholder =
        this.codeTarifRevatuaDisplayExpr(tarifRevatua);
    }

    // Set placeholder StockageRevatua
    if (
      this.article != null &&
      this.infosHeriteesDeLaClassification?.stockageRevatua != null
    ) {
      const stockageRevatua = this.stockageRevatuaList.find(
        (x) => x.id == this.infosHeriteesDeLaClassification?.stockageRevatua,
      );
      this.stockageRevatuaPlaceholder = stockageRevatua?.intitule;
    }
  }

  prixArticleEditorOptions: any = {
    icon: "edit",
    onClick: () => (this.showEditPrixRevientPopup = true),
  };

  opentPrixRevientPopup() {
    this.showEditPrixRevientPopup = true;
    this.prixRevientAvantModification = this.article.prixRevient;
  }

  async onUpdatePrixRevient() {
    await lastValueFrom(
      this.articlesLexiClient.updatePrixRevient(
        this.article.id,
        this.article.prixRevient,
      ),
    );
    this.showEditPrixRevientPopup = false;
  }

  async navigateToArticleParent() {
    if (!this.article.articleParentId) return;
    await this.router.navigate([`/article/${this.article.articleParentId}`]);
  }

  //#region Gestion des photos
  async getListPhotos() {
    const documents = await this.getDocuments();
    this.listPhotos = documents.filter(
      (x) =>
        x.contentType === ".png" ||
        x.contentType === ".jpeg" ||
        x.contentType === ".jpg",
    );
  }

  async addNewImage() {
    const formData = new FormData();
    const articleId = this.article.id.toString();
    var addedPhoto = this.addedPhotos;
    if (addedPhoto && addedPhoto.length > 0) {
      formData.append("ArticleId", articleId);
      formData.append("Files", addedPhoto[0]);

      // Effectuer la requête HTTP
      await this.http
        .post(window.location.origin + "/api/Images", formData)
        .toPromise();
      await this.getListPhotos();
      await this.loadPhotoPrincipale();

      // On rafraichit la liste des documents (sans attendre la fin de l'appel)
      this.documentListComponentRef?.setDocuments();
    }
    this.addedPhotos = [];
  }

  async loadPhotoPrincipale() {
    if (this.photoPrincipaleId == null) {
      this.photoPrincipaleId = this.listPhotos
        ?.slice()
        .sort((a, b) => a.zIndex - b.zIndex)[0]?.id;
    }
    this.selectedPhotoId = this.photoPrincipaleId;
    await this.showSelectedPhoto();
  }

  async onDocumentSupprime(documentSupprimeId: string) {
    await this.getListPhotos();

    // La photo principale a été supprimée
    if (documentSupprimeId == this.photoPrincipaleId) {
      this.photoPrincipaleId = null;
      await this.loadPhotoPrincipale();

      if (this.selectedPhotoId != null) {
        await this.changePhotoPrincipale();
      }
    }
  }

  async changePhotoPrincipale() {
    const newPhoto = {
      id: this.selectedPhotoId,
      objectId: this.article.id.toString(),
      objectType: ObjectType.article,
    };
    await lastValueFrom(
      this.imageLexiClient.changerPhotoPrincipale(
        newPhoto.id,
        newPhoto.objectId,
        newPhoto.objectType,
      ),
    );
    this.photoPrincipaleId = this.selectedPhotoId;
  }

  async showSelectedPhoto() {
    this.imageSrc =
      window.location.origin + "/api/images/" + this.selectedPhotoId;
  }
  //#endregion

  //#region Gestion des évènements

  onTabSelectionChanged = (e) => {
    const title = e?.addedItems?.[0]?.title;
    if (!this.isTabLoaded[title]) {
      this.isTabLoaded[title] = true;
    }
    switch (title) {
      case this.dictionnaireTabTitles[TabTitlesEnum.Classifications]:
        this.setTabClassificationsDataSources();
        break;
      case this.dictionnaireTabTitles[TabTitlesEnum.Achats]:
        this.setTabAchatsDataSources();
        break;
      case this.dictionnaireTabTitles[TabTitlesEnum.ComptaDouane]:
        this.setTabDouaneDataSources();
        break;
      case this.dictionnaireTabTitles[TabTitlesEnum.Logistique]:
        this.setLieuStockageArticleDataSource();
        this.setLieuStockageDataSource();
        break;
      default:
        break;
    }
  };

  private async setLieuStockageArticleDataSource() {
    if (this.lieuStockageArticleRef == null) return;
    this.lieuStockageArticleRef.lieuStockageArticleDataSource ??= await lastValueFrom(this.lieuStockageArticleLexiClient.getByArticleId(this.article.id));
  }

  private async setLieuStockageDataSource() {
    if (this.lieuStockageArticleRef == null) return;
    this.lieuStockageArticleRef.lieuStockageSociete ??= await lastValueFrom(this.lieuStockageLexiClient.getBySociete());
    this.lieuStockageArticleRef.setLieuStockage();
  }

  async onActionClick(e: any) {
    if (e.itemData.id == "edit") {
      this.isModificationEnCours = true;
      await this.getListPhotos();
    } else if (e.itemData.id == "dupliquer") {
      await this.onDuplicateArticle();
    }
  }

  private async onDuplicateArticle() {
    localStorage.setItem(this.doRefreshKey, "true");
    localStorage.setItem(
      this.storedArticleToDuplicateKey,
      JSON.stringify(this.article),
    );
    await this.router.navigate(["/article/nouveau"]);
  }

  async onCreateNewArticle() {
    this.showConfirmationTaxePopup = this.article.taxeGroupeId == null;

    if (!this.showConfirmationTaxePopup) {
      await this.createArticle();
    }
  }

  async createArticle() {
    // On empêche la création si les validations des comptes ne sont pas valides
    const formValidationResult: ValidationResult =
      this.form.instance.validate();
    if (!formValidationResult.isValid) return;

    const requestDto: CreateOrUpdateArticleRequestDto = {
      article: this.article,
      reporterModificationsSurVariantes: false
    };
    const newArticleId = await lastValueFrom(this.articlesLexiClient.createOrUpdate(requestDto));
    notify(
      { closeOnClick: true, message: "Article créé avec succès." },
      NotificationType.Success,
    );
    await this.router.navigate([`/article/${newArticleId}`]);
    window.location.reload();
  }

  async onUpdateArticle() {
    // On empêche la création si les validations des comptes ne sont pas valides
    const formValidationResult: ValidationResult =
      this.form.instance.validate();
    if (!formValidationResult.isValid) return;

    let reporterModificationsSurVariantes: boolean = false;
    if (this.articleAvecVariation) {
      reporterModificationsSurVariantes = await confirm(`Souhaitez-vous reporter les changements sur les variantes de cet article ?`, `Confirmation`);
    }
    const requestDto: CreateOrUpdateArticleRequestDto = {
      article: this.article,
      reporterModificationsSurVariantes
    };
    await lastValueFrom(this.articlesLexiClient.createOrUpdate(requestDto));

    if (this.selectedPhotoId != this.photoPrincipaleId) {
      await this.changePhotoPrincipale();
    }

    this.articleAvecVariation = this.article.avecVariation;
    this.isModificationEnCours = false;
    if(this.articleAvecVariation){
      await this.variantesArticleRef.setVariantes();
      await this.variantesArticleRef.setCaracteristiquesValeurs();
    }
    notify({ closeOnClick: true, message: "Article modifié avec succès." }, NotificationType.Success);
    await this.getListPhotos();
  }

  async onCancel() {
    this.isModificationEnCours = false;
    this.isImageLoading = true;
    await this.setArticle(this.article?.id);
    await this.loadPhotoPrincipale();
    this.isImageLoading = false;
  }

  async onClassificationsMisesAJour(e: GetClassificationDto[]) {
    this.classifications = e.filter(c =>
      c.classificationTypeId == this.societe.classificationSavId
      || c.classificationTypeId == this.societe.classificationComptableTypeId
    );
    this.isClassificationsDuParent = this.classifications.length > 0 && !this.classifications.some(x => x.type == ObjectType.article && x.itemId == this.article.id);

    // Si on supprime la dernière classification de la variante, on essaie de récupérer celles du parent
    if (e.length == 0 && this.article.articleParentId != null) {
      await this.setClassifications();
    }
  }
  //#endregion

  //#region Méthodes helpers
  private setTabClassificationsDataSources() {
    this.familleDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.familleService);
    this.articleDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.articleService);
  }

  private setTabAchatsDataSources() {
    const partenaireAdditionalParams = new Map().set("estClientOnly", false);
    this.partenaireDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.partenaireService, partenaireAdditionalParams);
  }

  private setTabDouaneDataSources() {
    this.nomenclatureDouaneDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.nomenclatureDouaneService);
    this.suffixeValeurDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.suffixeValeurService);
    this.codeExonerationDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.codeExonerationService);
    this.paysDataSource ??= this.dxDataSourceService.getDataSourceForSelectBox(this.paysService);
  }

  codeTarifRevatuaDisplayExpr(data: TarifRevatuaDto) {
    return data && `${data.code} - ${data.intitule}`;
  }

  compteGeneralDisplayExpr(data: CompteGeneralDto) {
    if (!data) {
      return "";
    }
    return data.code ? `${data.code} - ${data.intitule}` : data.intitule;
  }

  async getDocuments() {
    const documentFiltres = {
      objectId: this.article.id.toString(),
    };

    // Get Documents
    return await lastValueFrom(
      this.documentService.rechercher(documentFiltres),
    );
  }

  async loadHistorique(): Promise<void> {
    this.historiquePmp = await lastValueFrom(this.articlesLexiClient.getHistorique(this.article.id));
    this.showPopupHistorique = true;
  }

  //#endregion
}


export enum TabTitlesEnum {
  General,
  Variantes,
  Vente,
  ComptaDouane,
  Stock,
  Achats,
  Logistique,
  NumeroSerie,
  Historique,
  Caracteristiques,
  Classifications,
  Audit,
  Documents,
  Photos
}
import { Component, Input } from '@angular/core';
import { EvenementNature, FluxLexiClient, FluxStatut, HistoriqueFluxResult, ObjectType } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-flux-historique-list',
  templateUrl: './flux-historique-list.component.html',
  styleUrls: ['./flux-historique-list.component.scss'],
})
export class FluxHistoriqueListComponent {
  // Constantes
  readonly FluxStatut = FluxStatut;
  readonly fluxStatutDataSource = [
    { id: FluxStatut.new, intitule: "Brouillon" },
    { id: FluxStatut.paused, intitule: "En attente" },
    { id: FluxStatut.opened, intitule: "En cours" },
    { id: FluxStatut.closed, intitule: "Finalisé" },
  ];

  // Variables
  fluxHistoriqueList: HistoriqueFluxResult[];

  // Inputs
  /** Titre dataGrid */
  @Input() title: string = "Étapes réalisées";

  private _objectId: number;
  get objectId(): number {
    return this._objectId;
  }
  @Input() set objectId(value: number) {
    if (value != null && value != undefined) {
      this._objectId = value;
      this.setFluxHistorique();
    }
  }

  private _objectType: ObjectType = ObjectType.autre;
  @Input() set objectType(value: ObjectType) {
    this._objectType = value;
    this.setFluxHistorique();
  }
  get objectType(): ObjectType {
    return this._objectType;
  }

  private _toggle;
  public get toggle() {
    return this._toggle;
  }
  @Input()
  public set toggle(value) {
    this._toggle = value;
  }

  readonly evenementNatureDataSource = [
    { id: EvenementNature.etat, intitule: "État" },
    { id: EvenementNature.activite, intitule: "Activité" },
  ];

  constructor(
    private readonly fluxLexiClient: FluxLexiClient,
  ) { }

  //#region Méthodes principales
  async setFluxHistorique() {
    if (this.objectType == null || this.objectId == null) return;
    this.fluxHistoriqueList = await lastValueFrom(this.fluxLexiClient.getHistoriqueFluxForObject(this.objectType, this.objectId.toString()));
  }
  //#endregion
}

<div style="margin: 0 12px; display: flex; align-items: center;" *ngIf="selectedPersonnalisationGrille">
  <dx-drop-down-box
    [dataSource]="dataSource"
    [(value)]="selectedPersonnalisationGrille.intitule"
    [(opened)]="isDropDownBoxOpened"
    [acceptCustomValue]="true"
    [openOnFieldClick]="false"
    style="margin: 0;"
    displayExpr="intitule"
    (onEnterKey)="addItem()"
  >
    <dx-list
      id="list-preferences-grille"
      [dataSource]="dataSource"
      [allowItemDeleting]="true"
      selectionMode="single"
      displayExpr="intitule"
      (onSelectionChanged)="changeDropDownBoxValue($event)"
      (onItemDeleting)="onItemDeleting($event)"
    >
      <div *dxTemplate="let item of 'item'">
        <span [title]="item.intitule">{{ item.intitule }}</span>
      </div>
    </dx-list>
  </dx-drop-down-box>

  <dx-button
    style="margin-left: 6px;"
    stylingMode="text"
    icon="save"
    hint="Sauvegarder préférence de grille"
    [disabled]="selectedPersonnalisationGrille == null"
    (onClick)="save()"
  ></dx-button>
</div>
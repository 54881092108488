import { Component, Input, ViewChild } from '@angular/core';
import { BonsLexiClient, NumeroSerieDto } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { alert } from 'devextreme/ui/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-bon-detail-numero-serie',
  templateUrl: './bon-detail-numero-serie.component.html',
  styleUrls: ['./bon-detail-numero-serie.component.scss'],
})
export class BonDetailNumeroSerieComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  private _bonId: number;
  public get bonId(): number {
    return this._bonId;
  }
  @Input()
  public set bonId(value: number) {
    if (value != null) {
      this._bonId = value;
      this.setLignes();
    }
  }

  private _toggle: boolean;
  public get toggle(): boolean {
    return this._toggle;
  }
  @Input()
  public set toggle(value: boolean) {
    this._toggle = value;
  }
  lignes: NumeroSerieDto[] = [];
  isPointe: boolean = false;
  numerosSerie: string[];
  noSerieSaisi: string;

  @Input() isComptageEnCours: boolean = null;

  constructor(private readonly bonsLexiClient: BonsLexiClient) { }

  pointer() {
    this.isPointe = true;
  }

  annuler = async () => {
    this.isPointe = false;
    await this.setLignes();
  }

  valider = async () => {
    this.isPointe = false;
    await lastValueFrom(this.bonsLexiClient.updateArticleNumerosSerie(this.bonId, this.lignes));
    await this.setLignes();
  }

  async scan() {
    const dto: NumeroSerieDto = this.lignes.find(x => x.numeroSerie == this.noSerieSaisi.toUpperCase());
    this.noSerieSaisi = null;
    if (dto == null) {
      alert("Numéro de série inconnu", "Erreur");
      return;
    }
    dto.pointe = true;
  }

  calculateArticleCellValue(rowData) {
    return `${rowData.codeBo} - ${rowData.intitule}`;
  }

  private async setLignes() {
    this.lignes = await lastValueFrom(this.bonsLexiClient.getNumeroSerie(this.bonId));
    this.numerosSerie = this.lignes?.map(x => x.numeroSerie) ?? [];
  }

}

<dx-form [formData]="tpvSettings" class="form last plain-styled-form" labelMode="floating"
  [readOnly]="formIsReadOnly">
   <dxi-item itemType="tabbed">
      <dxo-tab-panel-options [showNavButtons]="true" stylingMode="secondary" tabsPosition="left"></dxo-tab-panel-options>
      <dxi-tab title="Saisie" [colCount]="2">
        <dxi-item dataField="clientParDefautId" [label]="{text: 'Client par défaut'}" [colSpan]="colSpan"
          editorType="dxSelectBox" [editorOptions]="{
        dataSource: partenaireDataSource,
        displayExpr: partenaireDisplayExpr,
        searchEnabled: true,
        valueExpr: 'id',
        showClearButton: true,
        searchExpr: ['intitule', 'codeBo'],
        showDataBeforeSearch: true
      }"></dxi-item>
        <dxi-item dataField="cumulScanSurUnArticle" [label]="{text: 'Cumul des scans sur un article'}"
          editorType="dxSelectBox" [editorOptions]="{
         dataSource: cumulScanSurUnArticleDataSource,
         valueExpr: 'id',
         displayExpr: 'intitule',
         showClearButton: true
       }"></dxi-item>
        <dxi-item dataField="typeRemiseParDefautId" [label]="{text: 'Type de remise par défaut'}"
          editorType="dxSelectBox" [editorOptions]="{
      dataSource: typeRemises,
      valueExpr: 'id',
      displayExpr: 'intitule',
      showClearButton: true
    }"></dxi-item>
        <dxi-item dataField="dureeAvertissementExpirationCarte" [label]="{text: 'Durée avertissement expiration carte'}"
          editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"
          [colSpan]="colSpan"></dxi-item>
        <dxi-item dataField="isCalculPrixUnitaireMultiTaxes" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
    text: 'Conversion HT/TTC multi taxe'
    , enableThreeStateBehavior: true
    }" [colSpan]="colSpan"></dxi-item>

        <dxi-item dataField="saisieVendeur" editorType="dxCheckBox" [label]="{visible: false}" [editorOptions]="{
    text: 'Saisie vendeur'
    , enableThreeStateBehavior: true
    }" [colSpan]="colSpan"></dxi-item>

        <dxi-item dataField="saisieObligVendeur" editorType="dxCheckBox" [label]="{visible: false}" [editorOptions]="{
    text: 'Saisie vendeur obligatoire'
    , enableThreeStateBehavior: true
    }" [colSpan]="colSpan"></dxi-item>

        <dxi-item dataField="terminerSurTicketOuFacture" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
      text: 'Terminer sur ticket ou facture'
      , enableThreeStateBehavior: true
      }" [colSpan]="colSpan"></dxi-item>
        <dxi-item dataField="messageAccueil" colSpan="2" [label]="{text: 'Message d\'accueil'}" editorType="dxTextArea"
          [editorOptions]="{height: 80}" >
        </dxi-item>

      </dxi-tab>
      <dxi-tab title="Gestion des fonds">
        <dxi-item dataField="optionPrelevementClotureCaisse" editorType="dxSelectBox"
          [label]="{ text: 'Prélèvement automatique à la clôture' }" [colSpan]="colSpan" [editorOptions]="{
            text: ' ',
            dataSource: optionPrelevementClotureCaisseDataSource,
            valueExpr: 'id',
            displayExpr: 'intitule',
            showClearButton: true
          }"
        ></dxi-item>

        <dxi-item dataField="fondReference" editorType="dxNumberBox" [label]="{ text: 'Fond de référence ' }"
          [editorOptions]="{text: ' '}" hint="Montant à atteindre par le prélèvement automatique effectué à la clôture"
          [colSpan]="colSpan"
        ></dxi-item>

        <dxi-item dataField="destinationPrelevementParDefautId" editorType="dxSelectBox"
          [label]="{ text: 'Destinaton des prélèvements par default' }" [editorOptions]="{
            text: '',
            dataSource: lieuStockageDataSource,
            valueExpr: 'id',
            displayExpr: 'intitule',
            showClearButton: true
          }" [colSpan]="colSpan"
        ></dxi-item>

        <dxi-item dataField="controleObligatoireOuverture" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Contrôle obligatoire à l\'ouverture',
            enableThreeStateBehavior: true
          }"
          [colSpan]="colSpan"
        ></dxi-item>

        <dxi-item dataField="controleObligatoireFermeture" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Contrôle obligatoire à la fermeture',
            enableThreeStateBehavior: true
          }"
          [colSpan]="colSpan"
        ></dxi-item>
        <dxi-item dataField="isLieuStockageApportPrelevementObligatoire" editorType="dxCheckBox"
          [label]="{visible: false}"
          [editorOptions]="{
            text: 'Stockage source/destination obligatoire pour les prélèvements/apports.',
            enableThreeStateBehavior: true
          }"
          [colSpan]="colSpan"
        ></dxi-item>
      </dxi-tab>

      <dxi-tab title="Stock">
        <dxi-item dataField="utilisationEntrepotPreferentiel" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Utilisation entrepot préférentiel',
            enableThreeStateBehavior: true
          }" [colSpan]="colSpan"
        ></dxi-item>

        <dxi-item dataField="multiEntrepotEnabled" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Activation multi-entrepôts',
            enableThreeStateBehavior: true
          }" [colSpan]="colSpan"
        ></dxi-item>
        <dxi-item dataField="isInventaireObligatoireAvantCloture" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Forcer l\'inventaire à la clôture.',
            enableThreeStateBehavior: true
          }" [colSpan]="colSpan"
        ></dxi-item>
        <dxi-item dataField="forcerVerificationStockAvantAjoutArticle" editorType="dxCheckBox"
          [label]="{visible: false}"
          [editorOptions]="{
            text: 'Forcer vérification du stock à l\'ajout d\'article',
            enableThreeStateBehavior: true
          }" [colSpan]="colSpan"
        ></dxi-item>

        <dxi-item dataField="bloquerVenteSiCaisseHorsConnexionEtVerificationStockForcee" editorType="dxCheckBox"
          [label]="{visible: false}"
          [editorOptions]="{
            text: 'Bloquer vente si caisse hors connexion et vérification du stock forcée',
            enableThreeStateBehavior: true
          }" [colSpan]="colSpan"
        ></dxi-item>
      </dxi-tab>

      <dxi-tab title="Sécurité" [colCount]="2">
        <dxi-item itemType="group">
          <dxi-item dataField="authentificationType" [label]="{text: 'Type d\'authentification'}" [colSpan]="colSpan"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: caisseTypeAuthentification,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item dataField="authentificationDomain" [label]="{text: 'Domaine d\'authentification'}"
            [colSpan]="colSpan"
          ></dxi-item>
          <dxi-item dataField="elevationPrivilegeAuthentificationType"
            [label]="{text: 'Type d\'authentification pour l\'élévation de privilège'}" [colSpan]="colSpan"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: elevationPrivilegeAuthentificationType,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
        </dxi-item>

        <dxi-item itemType="group">
          <dxi-item dataField="identificationAvantVente" [label]="{visible: false}" editorType="dxCheckBox"
            [editorOptions]="{
              text: 'Identification avant vente',
              enableThreeStateBehavior: true
            }" [colSpan]="colSpan"
          ></dxi-item>
          <dxi-item dataField="verrouillageApresVente" editorType="dxCheckBox" [label]="{visible: false}"
            [editorOptions]="{
              text: 'Verrouillage après une vente',
              enableThreeStateBehavior: true
            }" [colSpan]="colSpan"
          ></dxi-item>
          <dxi-item dataField="multiCaissier" editorType="dxCheckBox" [label]="{visible: false}"
            [editorOptions]="{
              text: 'Activation multi-caissier',
              enableThreeStateBehavior: true
            }" [colSpan]="colSpan"
          ></dxi-item>
          <dxi-item dataField="pieceRechercheAvancee" editorType="dxCheckBox" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Panneau de recherche avancée de pièce',
            enableThreeStateBehavior: true
          }" [colSpan]="colSpan"
        ></dxi-item>
        </dxi-item>
      </dxi-tab>

      <dxi-tab title="Matériel" [colCount]="4">
        <dxi-item dataField="lienTpe" editorType="dxCheckBox" [colSpan]="1" [label]="{visible: false}"
          [editorOptions]="{
            text: 'Lien TPE',
            enableThreeStateBehavior: true
          }"
        ></dxi-item>
        
        <dxi-item dataField="versionCibleMiseAJour" editorType="dxSelectBox" [editorOptions]="versionsTpvOptions"  [colSpan]="1" [label]="{text: 'Version pour mise à jour'}"></dxi-item>
        <dxi-item dataField="cleLicenceWacomSignature" [colSpan]="2"
          [label]="{text: 'Clé d\'utilisation WACOM'}"
        ></dxi-item>
      </dxi-tab>

      <dxi-tab title="Fret armateur">
        <dxi-item dataField="activerModeFret" [label]="{text:'Activer mode fret'}" editorType="dxSwitch"></dxi-item>
      </dxi-tab>
    </dxi-item>
</dx-form>
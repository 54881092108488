import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContactResultDto, ContactsLexiClient, ContactFilterDto } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: "app-contacts-page",
  templateUrl: "./contacts-page.component.html",
  styleUrls: ["./contacts-page.component.scss"],
})
export class ContactsPageComponent implements OnInit {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid; }
  @ViewChild(DxDataGridComponent) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value && this.heightToDeduce != null) {
      value.instance.element().style.height = `calc(100vh - ${this.heightToDeduce}px)`;
    }
  }
  grilleStorageKey = "grille_contacts_list";
  private _heightToDeduce: number;
  get heightToDeduce() { return this._heightToDeduce; }
  @Input() set heightToDeduce(value: number) {
    this._heightToDeduce = value;
    if (value && this.dataGrid != null) {
      this.dataGrid.instance.element().style.height = `calc(100vh - ${value}px)`;
    }
  }

  contacts: ContactResultDto[] = [];

  constructor(
    private readonly contactService: ContactsLexiClient,
  ) {}

  async ngOnInit() {
    await this.setContacts();
  }

  async setContacts(
    filtre: ContactFilterDto = {
      inclureInactif: true,
      objetLies: []
    }
  ) {
    this.contacts = await lastValueFrom(this.contactService.rechercher(filtre));
  }
}
